<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import Merchandising from '@/services/Merchandising';

    export default {
        components: { Layout, PageHeader,  vueDropzone: vue2Dropzone },
        page: {
            title: "Media",
                 meta: [
                   {
                     name: "description",
                     content: appConfig.description,
                   },
                 ],
        },
        data() {
            return {
                title: "Media",
                items: [
                     {
                       text: "Merchandising",
                     },
                     {
                       text: "Media",
                       active: true,
                     },
                ],
                showLoader:false,
                error: null,
                showModal: false,
                modalData: [],
                link_id: null,
                results: {},
                form: {
                    csrf_token: localStorage.getItem('csrf_token'),
                    selectedKey: "",
                    selectedValue: "",
                },
                show_add_image:false,
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles:'image/*',
                    maxFiles: 1,
                },
                showForm:false,
            };
        },
        mounted() {
             this.getMedia();
        },
        methods: {


            async submitForm() {
              //  try {
                        let formData = new FormData();

                        var index=0;
                         this.modalData.forEach((element) => {
                            index++;

                            formData.append(index+'-position', element.position);
                            formData.append(index+'-link_url', element.link_url);
                            formData.append(index+'-image_url', element.image_url);

                         });

                    await Merchandising.updateMedia(formData).then((response) => {
                         this.successmsg('Media Updated!');
                    }).catch(error => {
                        this.failedmsg('Failed to Update Media.');
                    }).finally(() => {
                        this.tryingToEdit = false;
                        this.getMedia();
                        this.show_add_image = false;
                    })
              //  } catch (error) {
             //       this.failedmsg('Failed to Update Media.');
            //    }

            },

            async getMedia() {
                try {
                    this.showLoader = true
                    this.toggleBusy();

                    const response = await Merchandising.getMedia();
                    this.showLoader = false;
                    this.modalData = response.data.data;
                    if(this.modalData.length == 0){
                        this.showForm = false;
                    } else {
                        this.showForm = true;
                    }


                }
                catch (error) {
                    this.toggleBusy();
                }
            },

        selectFileSmall(files){
             setTimeout(() => {

                    this.$refs.myVueDropzone_primary.forEach((dropZone) => {
                        var row_number = dropZone.$attrs.row_index;
                        let queuedFiles = dropZone.getQueuedFiles();

                         if (queuedFiles.length > 1) {
                            this.failedmsg('You can only have one file')

                            files.forEach((value) => {
                                dropZone.removeFile(value)
                            });
                        }

                          if (typeof queuedFiles[0] !== 'undefined') {
                               this.modalData[row_number].image_url = queuedFiles[0]
                          }
                    });

             }, 250);
        },

         viewImage(index, image_url) {
            var id_view = 'view_btn_'+index
            var id_change = 'change_btn_'+index

            document.getElementById(id_view).style.display = 'none';
            document.getElementById(id_change).style.display = 'inline-block';

            if(image_url){
                document.getElementById('show_image_'+index).style.display = 'inline-block';
            }
            document.getElementById('image_url_'+index).style.display = 'none';

        },
        changeImage(index,image_url) {
            console.log(index);
            console.log(image_url);

          var id_view = 'view_btn_'+index
          var id_change = 'change_btn_'+index

          document.getElementById(id_view).style.display = 'inline-block';
          document.getElementById(id_change).style.display = 'none';

          if(image_url){
            document.getElementById('show_image_'+index).style.display = 'none';
          }
          document.getElementById('image_url_'+index).style.display = 'inline-block';

        },


        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-12" v-if="!showLoader">
                            <div class="col-sm-12 col-md-12 mb-5">
                                <div v-if="showForm" id="tickets-table_length" class="dataTables_length">
                                    <form @submit.prevent="submitForm">
                                        <div class="row">
                                            <div class="col-2"><label  style="font-size: 20px;">Position </label></div>
                                            <div class="col-3"><label  style="font-size: 20px;">Image URL </label></div>
                                            <div class="col-7"><label  style="font-size: 20px;">Link URL </label></div>
                                        </div>
                                        <div v-for="(params, index) in modalData" :key="index">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <span :id="'position_' + index" >{{ params.position }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                 <div class="col-3">
                                                    <div class="form-group">


                                                          <template  >
                                                             <b-form-group :id="'show_image_' + index" v-if="params.image_url != ''" label-for="image" class="mb-1  image-area" >
                                                                 <img  :src="params.image_url" alt=""  class="img-thumbnail" >
                                                             </b-form-group>
                                                         </template>

                                                         <template >
                                                            <vue-dropzone style="display:none"
                                                                    :id="'image_url_' + index"
                                                                    :name="'image_url_' + index"
                                                                    ref="myVueDropzone_primary"
                                                                    :use-custom-slot="true"
                                                                    :options="dropzoneOptions_primary"
                                                                    @vdropzone-files-added="selectFileSmall"
                                                                    no-progress-bar
                                                                    :row_index=index
                                                                    v-model="params.image_url"
                                                            >
                                                                <div class="dropzone-custom-content">
                                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                    <h4>Drop file here or click to upload.</h4>
                                                                </div>
                                                            </vue-dropzone>
                                                         </template>

                                                        <!-- <a  href="javascript:void(0);" class="btn btn-primary btn-sm mb-2" @click="show_add_image = !show_add_image">{{show_add_image ? 'View Image' : 'Change Image'}}</a>-->
                                                        <div class="col-sm-12">
                                                            <a :id="'view_btn_' + index" href="javascript:void(0);" class="btn btn-primary btn-sm mb-2" @click="viewImage(index)" style="display:none;">View Image</a>
                                                            <a :id="'change_btn_' + index"  href="javascript:void(0);" class="btn btn-primary btn-sm mb-2" @click="changeImage(index)" >Change Image</a>
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-7">
                                                    <div class="form-group">
                                                        <div class="form-group">

                                                            <input :id="'link_url_' + index" v-model="params.link_url" class="form-control" :name="'link_url_' + index" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <input type="hidden" name="csrf_token" v-model="form.csrf_token" />
                                        <button style="margin-top: 15px" type="submit" class="btn btn-primary">Update Media</button>
                                    </form>
                                </div>
                                <div v-else>
                                        No data
                                </div>
                            </div>
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                <b-spinner large></b-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>